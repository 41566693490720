<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col
        v-for="chapter in data"
        :key="chapter.id"
        lg="6"
        sm="6"
      >
        <b-card>
          <div class="mb-1">
            <h4 class="mb-0">
              {{ chapter.name }} {{ chapter.translation ? ("- "+ chapter.translation.name) : '' }}
            </h4>
          </div>
          <div class="d-flex flex-wrap">
            {{ chapter.description }} {{ chapter.translation ? ("- "+ chapter.translation.description) : '' }}
          </div>
          <div class="mt-1">
            <b-row>
              <b-col cols="4">
                <b-card-text>
                  <h5>Sections: {{ chapter.sections.length }}</h5>
                </b-card-text>
              </b-col>
              <b-col cols="4">
                <b-card-text>
                  <h5>Lessons: {{ chapter.lessons.length }}</h5> 
                </b-card-text>
              </b-col>
            </b-row>
          </div>
          <div class="mt-1">
            <b-button variant="outline-warning"
              :to="{name: 'teacher-sections-view', params: {chapter_id: chapter.id}}"
              >View Sections</b-button
            >
          </div>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BCard, BButton, BCardText } from 'bootstrap-vue'

import { kFormatter } from '@core/utils/filter'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardText,
  },
  data() {
    return {
      data: [],
    }
  },
  async mounted() {
    await this.$http.get('admin/teacher/chapters/all')
      .then(response => { this.data = response.data.data })
  },
  methods: {
    kFormatter,
  },
}
</script>
